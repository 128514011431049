<template>
  <section class="faq">
    <v-container>
      <div class="faq__header">Вопросы и ответы</div>
      <v-expansion-panels v-model="openPanels" multiple class="mt-4">
        <v-expansion-panel v-for="(panel, idx) in CONFIG" :key="idx">
          <v-expansion-panel-header>
            <div class="faq__title">
              {{ panel.title }}
            </div>
            <template v-slot:actions>
              <v-icon v-if="!isOpen(idx)" color="tertiaryText">
                mdi-chevron-down
              </v-icon>
              <v-icon v-else color="primary"> mdi-close </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="faq__subtitle" v-html="panel.subtitle" />
            <div
              v-if="panel.isForm"
              class="mt-3 mt-sm-5 d-flex flex-column faq__panel-form flex-lg-row"
            >
              <div class="faq__panel-form-container">
                <v-form v-model="valid" ref="form">
                  <div class="faq__fields contrast">
                    <div class="mt-3 mx-3 mb-2">
                      <v-text-field
                        solo
                        hide-details
                        v-model="form.email"
                        :rules="RULES.email"
                        label="Электронная почта"
                        class="faq__input"
                      />
                    </div>
                    <div class="faq__divider" />
                    <div class="mt-2 mx-3 mb-3">
                      <v-textarea
                        solo
                        hide-details
                        v-model="form.text"
                        :rules="RULES.text"
                        label="Что хотите узнать"
                        class="faq__textarea"
                      />
                    </div>
                  </div>
                  <v-button
                    v-if="isDesktop"
                    :disabled="!valid"
                    x-small
                    color="primary"
                    class="faq__button mt-5"
                    @click="senForm"
                  >
                    {{ panel.buttonText }}
                  </v-button>
                </v-form>
              </div>
              <div class="d-flex ml-lg-10">
                <img
                  :src="panel.descriptionImg"
                  class="mr-3 faq__panel-desc-image"
                />
                <div
                  v-html="panel.description"
                  class="faq__panel-description secondaryText--text"
                />
              </div>
              <v-button
                v-if="!isDesktop"
                :disabled="!valid"
                :block="isMobile"
                x-small
                color="primary"
                class="faq__button"
                @click="senForm"
              >
                {{ panel.buttonText }}
              </v-button>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </section>
</template>

<script>
import { VButton } from "@/components/ui"
import { size } from "@/core/mixins"

const CONFIG = Object.freeze([
  {
    title: "Как начать работу",
    subtitle: `
      1. <a href="https://xn--80akthehdbfhd.xn--80ahlldqgjs.xn--p1ai/auth/register" class="text-decoration-none primary--text">Зарегистрируйтесь в партнёрской программе</a> и вы получите доступ в личный кабинет партнёра  
      <br><br>

      2. Выберите интересующий раздел интернет-магазина или мобильного приложения Спортмастер, которая будет интересна вашим подписчикам, друзьям или аудитории
      <br><br>

      3.  В личном кабинете партнёра получите реферальную ссылку на выбранный раздел Спортмастера или возьмите уже созданную для вас реферальную ссылку при регистрации. Также вам будет доступен личный промокод на скидку 20% на категории одежда и обувь для ваших клиентов
      <br><br>

      4. Поделитесь реферальной ссылкой и личным промокодом с вашей аудиторией
      <br><br>

      5. Получите вознаграждение за выкупленные заказы, совершённые по вашей ссылке. <br>
Вы получите вознаграждение за товары, которые будут приобретены в течение 3 дней после перехода клиента по вашей ссылке: даже за товары из других разделов Спортмастера. <br>
Выкупленным товаром считается товар, по которому прошел период возврата в 14 календарных дней


    `,
  },
  {
    title: "Что можно рекомендовать",
    subtitle: `Вы можете делиться реферальными ссылками на любые разделы Интернет-магазина или мобильного приложения Спортмастер 
    <br><br>
      Это могут быть: <br>
      <ul>
        <li>разделы с товарами</li>
        <li>акции и специальные предложения</li>
        <li>брендами товаров</li>
        <li>готовые образы</li>
        <li>собранная вами корзина товаров</li>
        <li>подборки товаров по заданным фильтрам</li>
        <li>другие страницы Интернет-магазина или Мобильного приложения Спортмастер</li>
      </ul>`,
  },
  {
    title: "Как получить вознаграждение",
    subtitle:
      "Вознаграждение может быть получено на банковскую карту или расчётный счёт",
  },
  {
    title: "Когда начисляется вознаграждение?",
    subtitle:
      "Вознаграждение начисляется один раз в месяц до 15 числа месяца следующего за отчетным",
  },
  {
    title: "Что такое реферальная ссылка?",
    subtitle: `
      Это специальная ссылка, ведущая на сайт <a href="https://sportmaster.ru" class="text-decoration-none primary--text">https://sportmaster.ru</a>, 
      которую вы можете отправлять другим людям, чтобы они, перейдя по ней, совершали покупки. Если эти люди совершат покупку, то вы получите вознаграждение
    `,
  },
  // {
  //   title: 'Задать вопрос',
  //   subtitle: 'Постараемся ответить как можно быстрее',
  //   isForm: true,
  //   buttonText: 'Отправить',
  //   description: 'Можете оставить запрос через форму <a :href="/" class="text-decoration-none primary--text">в личном кабинете</a> партнера «Лидпортал»',
  //   descriptionImg: require('@/assets/images/faq/mail.png')
  // }
])

const RULES = Object.freeze({
  text: [
    (v) => !!v || 'Поле "Вопрос" обязательно',
    (v) => v.length > 10 || 'Поле "Вопрос" не может состоять из 10 символа',
  ],
  email: [
    (v) => !!v || 'Поле "Email" обязательно',
    (v) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(v) ||
      'Поле "Email" не верное',
  ],
})

const FORM_DEFAULT = {
  email: "",
  text: "",
}

export default {
  name: "FaqExpansions",
  components: { VButton },
  mixins: [size],
  data() {
    return {
      RULES,
      CONFIG,
      openPanels: [],
      form: Object.assign({}, FORM_DEFAULT),
      valid: false,
    }
  },
  methods: {
    isOpen(idx) {
      return this.openPanels.includes(idx)
    },
    senForm() {
      console.log("send")
      this.form = Object.assign({}, FORM_DEFAULT)
      this.$refs.form?.resetValidation?.()
    },
  },
}
</script>

<style lang="scss" scoped>
.faq {
  &__header {
    font-weight: 700;
    font-size: 24px;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      font-size: 48px;
    }
    @media (min-width: map-get($grid-breakpoints, "md")) {
      font-size: 54px;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      font-size: 28px;
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      font-size: 20px;
    }
  }

  &__divider {
    height: 1px;
    width: 100%;
    background-color: #e3e3e3;
  }

  &__fields {
    border-radius: 32px;
    border: 1px solid #e3e3e3;
  }

  &__input,
  &__textarea {
    border-radius: 12px;
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      border-radius: 20px;
    }

    ::v-deep {
      &.v-input--is-focused {
        border: 1px solid;
      }
      & input,
      & textarea {
        font-weight: 400;
        font-size: 14px;

        @media (min-width: map-get($grid-breakpoints, "sm")) {
          font-size: 18px;
        }
      }

      .v-label {
        color: #d0d0d0;
        font-weight: 400;
        font-size: 14px;

        @media (min-width: map-get($grid-breakpoints, "sm")) {
          font-size: 18px;
        }
      }
    }
  }

  &__panel {
    &-form {
      row-gap: 16px;

      &-container {
        width: 100%;
        @media (min-width: map-get($grid-breakpoints, "lg")) {
          width: 793px;
        }

        ::v-deep {
          & .v-input__control > .v-input__slot {
            box-shadow: unset !important;
          }
        }
      }
    }
    &-description {
      font-weight: 400;
      font-size: 12px;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        font-size: 14px;
      }
    }
    &-desc-image {
      height: 22px;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        font-size: 33px;
      }
    }
  }

  &__button {
    @media (min-width: map-get($grid-breakpoints, "sm")) {
      width: 180px;
    }
    ::v-deep {
      &.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: #c7d4f2 !important;
        color: #fff !important;
      }
    }
  }

  ::v-deep {
    .v-expansion-panels {
      row-gap: 10px;
      @media (min-width: map-get($grid-breakpoints, "sm")) {
        row-gap: 12px;
      }
    }

    .v-expansion-panel--active + .v-expansion-panel,
    .v-expansion-panel--active:not(:first-child) {
      margin-top: unset;
    }

    .v-expansion-panel {
      border-radius: 16px !important;
      border: 1px solid #d9e8fa;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        border-radius: 20px !important;
      }
    }

    .v-expansion-panel-header {
      padding: 16px 20px;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        padding: 32px;
      }
      @media (min-width: map-get($grid-breakpoints, "md")) {
        padding: 40px;
      }
    }
    .v-expansion-panel-content__wrap {
      padding: 16px 20px;
      padding-top: 0;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        padding: 32px;
        padding-top: 0;
      }
      @media (min-width: map-get($grid-breakpoints, "md")) {
        padding: 40px;
        padding-top: 0;
      }
    }

    .v-expansion-panel--active {
      border-color: transparent;
      background: #f2f6ff;
    }

    .v-expansion-panel::before {
      box-shadow: unset;
    }
    .v-expansion-panel::after {
      border: unset;
    }
    .v-expansion-panel-header__icon {
      margin-left: 10px;
    }
  }
}
</style>
